import { RawHtml } from '$shared/components/RawHtml';
import React from 'react';
import { M100RichTextModule } from '~/lib/data-contract';
import { useModuleTheme } from '../../hooks/useModuleTheme';
import { ModuleContainer } from '../ModuleContainer';
import { ModuleHeader } from '../ModuleHeader';
import { StyledM100RichText } from './styled';

export type M100RichTextProps = M100RichTextModule;

export const M100RichText = ({
    text,
    headline,
    subHeadline,
    subSubHeadline,
    ...rest
}: M100RichTextProps) => {
    const { textColor } = useModuleTheme(rest);

    return (
        <ModuleContainer hasGutter {...rest}>
            <ModuleHeader
                headline={headline}
                subHeadline={subHeadline}
                subSubHeadline={subSubHeadline}
                textColor={textColor}
            />
            <StyledM100RichText textColor={textColor}>
                {text && <RawHtml html={text} />}
            </StyledM100RichText>
        </ModuleContainer>
    );
};
